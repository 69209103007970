import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { sendContactFormThunk } from '../../store/reducers/actions'
import s from './ContactForm.module.css'
import NewCustomModal from '../ui/newCustomModal/newCustomModal'
import RedBtn from '../ui/redBtn/RedBtn'
import { t } from 'i18next'

const ContactForm = () => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' })
  const [errors, setErrors] = useState({})
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [popupType, setPopupType] = useState('')

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const newErrors = {}

    if (!formData.name.trim()) newErrors.name = 'ФИО обязательно для заполнения'
    if (!emailRegex.test(formData.email)) newErrors.email = 'Некорректный адрес электронной почты'
    if (!formData.phone.trim()) newErrors.phone = 'Номер телефона обязателен для заполнения'
    if (!formData.message.trim()) newErrors.message = 'Сообщение обязательно для заполнения'

    return newErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validateForm()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    setIsLoading(true)
    try {
      await dispatch(sendContactFormThunk(formData))
      setPopupType('confirm')
      setIsPopupOpen(true)
      setFormData({ name: '', email: '', phone: '', message: '' })
    } catch (error) {
      setPopupType('error')
      setIsPopupOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='container'>
      <NewCustomModal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} popupType={popupType} />

      <div className={s.contact_form}>
        {/* left side */}
        <div className={s.info}>
          <h3>{t('contact.address')}</h3>
          <p>
            {t('contact.representative_office')} Glandore Office Building, Fitzwilliam Hall, 26 Fitzwilliam Pl, Dublin 2, D02
            T292, Ireland
          </p>
          <p className={s.gray}>{t('contact.note')}</p>
          <p>{t('contact.head_office')} Bonovo Road — Fomboni Island of Mohéli — Comoros Union</p>
          <h3>{t('contact.working_hours')}</h3>
          <p>{t('contact.working_hours_t')}</p>
          <h3>{t('contact.email')}</h3>
          <p>
            <a href='mailto:info@mezhdunarodnaya-sberkassa.com'>info@mezhdunarodnaya-sberkassa.com</a>
          </p>
          <h3>{t('contact.website')}</h3>
          <p>
            <a href='http://www.mezhdunarodnaya-sberkassa.com'>www.mezhdunarodnaya-sberkassa.com</a>
          </p>
          <h3>{t('contact.phone')}</h3>
          <p>
            <a href='tel:+353818883938'>+353 81 888 3938</a>
          </p>
        </div>

        {/* right side [inputs] */}
        <form onSubmit={handleSubmit} className={s.input_form} id='contact-form'>
          <div className={s.input_block}>
            <label className={s.lable}>{t('contact.form.name')}</label>
            <input
              className={`${s.input} ${formData.name ? s.filled : ''}`}
              placeholder={t('contact.form.name_PH')}
              type='text'
              name='name'
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <span className={s.error}>* {errors.name}</span>}
          </div>

          <div className={s.input_block}>
            <label className={s.lable}>{t('contact.form.email')}</label>
            <input
              className={`${s.input} ${formData.email ? s.filled : ''}`}
              placeholder={t('contact.form.email_PH')}
              type='text'
              name='email'
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className={s.error}>* {errors.email}</span>}
          </div>

          <div className={s.input_block}>
            <label className={s.lable}>{t('contact.form.phone')}</label>
            <input
              className={`${s.input} ${formData.phone ? s.filled : ''}`}
              placeholder={t('contact.form.phone_PH')}
              type='tel'
              name='phone'
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <span className={s.error}>* {errors.phone}</span>}
          </div>

          <div className={s.input_block}>
            <label className={s.lable}>{t('contact.form.feedback')}</label>
            <textarea
              className={`${s.input} ${formData.message ? s.filled : ''}`}
              placeholder={t('contact.form.feedback_PH')}
              type='text'
              name='message'
              value={formData.message}
              onChange={handleChange}
              rows={12}
            />
            {errors.message && <span className={s.error}>* {errors.message}</span>}
          </div>

          <div className={s.form_btn}>
            <RedBtn type='submit' btnLabel={isLoading ? t('buttons.loading') : t('buttons.send')} fullWidth />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
