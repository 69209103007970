import React, { useState } from 'react'
import './Home.css'

import { useDispatch } from 'react-redux'
import { sendContactFormThunk } from '../../store/reducers/actions'

import HeroWithBanner from '../../components/hero-with-banner/HeroWithBanner'
import RedBtn from '../../components/ui/redBtn/RedBtn'
import MyInput from '../../components/ui/MyInput/MyInput'
import NewCustomModal from '../../components/ui/newCustomModal/newCustomModal'
import heroBanner from '../../assets/home/hero-banner.png'
import about_bank_today from '../../assets/home/about-bank-today.png'
import advantages_cards from '../../assets/home/advantages-cards-img.webp'
import { t } from 'i18next'

const Home = () => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' })
  const [errors, setErrors] = useState({})
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupType, setPopupType] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const newErrors = {}

    if (!formData.name.trim()) newErrors.name = 'ФИО обязательно для заполнения'
    if (!emailRegex.test(formData.email)) newErrors.email = 'Некорректный адрес электронной почты'
    if (!formData.phone.trim()) newErrors.phone = 'Номер телефона обязателен для заполнения'

    return newErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validateForm()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    setIsLoading(true)
    try {
      await dispatch(sendContactFormThunk(formData))
      setPopupType('confirm')
      setIsPopupOpen(true)
      setFormData({ name: '', email: '', phone: '' })
    } catch (error) {
      setPopupType('error')
      setIsPopupOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const heroData = {
    heroImage: heroBanner,
    title: t('home.title'),
    desc: t('home.hero'),
    btnLabel: t('buttons.learn_more'),
    btnLink: '/about-bank',
    bgGradient: true,
    textLeft: false,
  }

  return (
    <section className='section'>
      {/* hero banner */}
      <HeroWithBanner {...heroData} />

      {/* quote container */}
      <div className='quote container'>
        <p>
          {t('home.quote_1._1')} <span>{t('home.quote_1._2')}</span>.
        </p>
      </div>

      {/* about bank today */}
      <div class='about_bank_today container'>
        {/* top flex section */}
        <div className='about_bank_top'>
          <div className='left'>
            <h3>{t('home.bank_today.title')}</h3>
            <p>{t('home.bank_today.subtitle')}</p>
          </div>
          <img src={about_bank_today} alt='About Bank Today' className='right' />
        </div>
        {/* overview points */}
        <div className='overview_points'>
          <div className='line'>
            <p className='number'>01.</p>
            <p className='text'>{t('home.bank_today.line_1')}</p>
          </div>
          <div className='line'>
            <p className='number'>02.</p>
            <p className='text'>{t('home.bank_today.line_2')}</p>
          </div>
          <div className='line'>
            <p className='number'>03.</p>
            <p className='text'>{t('home.bank_today.line_3')}</p>
          </div>
          <div className='line'>
            <p className='number'>04.</p>
            <p className='text'>{t('home.bank_today.line_4')}</p>
          </div>
        </div>
        {/* Button */}
        <div className='btn'>
          <RedBtn btnLabel={t('buttons.learn_more')} btnLink='/about-bank' arrow />
        </div>
      </div>

      {/* advantages package */}
      <div className='advantages_package container'>
        {/* header section */}
        <div className='header_section'>
          <div className='left'>
            <h2>{t('home.advantage_pkg.title')}</h2>
            <p>{t('home.advantage_pkg.subtitle')}</p>
          </div>

          {/* for lg devices only */}
          <div className='right'>
            <RedBtn btnLabel={t('buttons.open_account')} btnLink='/register' arrow />
          </div>
        </div>

        {/* advantages cards */}
        <div className='advantages_flex'>
          <img src={advantages_cards} alt='advantages package' className='left' />
          {/* grid container */}
          <div className='right'>
            <div className='grid_container'>
              <div className='card bg_gradient'>
                <h3>{t('home.advantage_pkg.card_1')}</h3>
                <p>{t('home.advantage_pkg.card_1_text')}</p>
              </div>
              <div className='card'>
                <h3>{t('home.advantage_pkg.card_2')}</h3>
                <p>{t('home.advantage_pkg.card_2_text')}</p>
              </div>
              <div className='card'>
                <h3>{t('home.advantage_pkg.card_3')}</h3>
                <p>{t('home.advantage_pkg.card_3_text')}</p>
              </div>
              <div className='card bg_gradient'>
                <h3>{t('home.advantage_pkg.card_4')}</h3>
                <p>{t('home.advantage_pkg.card_4_text')}</p>
              </div>
            </div>
          </div>
        </div>
        {/* for lg devices only */}
        <div className='mobile_btn'>
          <RedBtn btnLabel={t('buttons.open_account')} btnLink='/register' arrow />
        </div>
      </div>

      {/* Qoute Container */}
      <div className='quote container'>
        <p>
          {t('home.quote_2._1')} <span>{t('home.quote_2._2')}</span> — <span>{t('home.quote_2._3')} </span>
          {t('home.quote_2._4')}
        </p>
      </div>

      <div className='container'>
        <NewCustomModal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} popupType={popupType} />

        <div className='home-contact-container'>
          <p className='home-contact-title'>{t('home.contact.title')}</p>

          <form className='contact-form' onSubmit={handleSubmit}>
            <div className='input-block'>
              <div className='label'>{t('home.contact.name')}</div>
              <MyInput
                id='name'
                name='name'
                value={formData.name}
                onChange={handleChange}
                placeholder={t('home.contact.name_PH')}
              />
              {errors.name && <p className='home-contact-error'>* {errors.name}</p>}
            </div>
            <div className='input-block'>
              <div className='label'>{t('home.contact.email')}</div>
              <MyInput
                id='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                placeholder={t('home.contact.email_PH')}
              />
              {errors.email && <p className='home-contact-error'>* {errors.email}</p>}
            </div>
            <div className='input-block'>
              <div className='label'>{t('home.contact.phone')}</div>
              <MyInput
                id='phone'
                name='phone'
                placeholder={t('home.contact.phone_PH')}
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <p className='home-contact-error'>* {errors.phone}</p>}
            </div>

            <RedBtn type='submit' btnLabel={isLoading ? t('buttons.loading') : t('buttons.submit')} fullWidth />
          </form>
        </div>
      </div>
    </section>
  )
}

export default Home
