import React, { useState, useRef, useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import './Header.css'
import logo from '../../assets/header/logo.svg'
import arrowUpRed from '../../assets/header/arrow-up-red.svg'
import arrowDownRed from '../../assets/header/arrow-down-red.svg'
import arrowDownBlue from '../../assets/header/arrow-down-blue.svg'
import arrowUpBlue from '../../assets/header/arrow-up-blue.svg'
import burgerIcon from '../../assets/header/burger-icon.svg'
import closeIcon from '../../assets/header/close-icon.svg'
import langArrow from '../../assets/header/language-dropdown-arrow.svg'
import RedBtn from '../../components/ui/redBtn/RedBtn'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false) // State to manage menu visibility on small screens
  const dropdownTimeoutRef = useRef(null)
  const headerRef = useRef(null)
  const [isUpArrowRed, setIsUpArrowRed] = useState(false)

  const location = useLocation()
  const [activeNavItem, setActiveNavItem] = useState('/')
  useEffect(() => {
    setActiveNavItem(location.pathname)
  }, [location])

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen) // Toggle the menu visibility
  }

  const handleScroll = id => {
    const anchor = document.getElementById(id)
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleMouseEnter = () => {
    if (dropdownTimeoutRef.current) {
      clearTimeout(dropdownTimeoutRef.current)
    }
    setDropdownOpen(true)
    setIsUpArrowRed(true)
  }

  const handleMouseLeave = () => {
    dropdownTimeoutRef.current = setTimeout(() => {
      setDropdownOpen(false)
    }, 200) // Adjust the delay as needed
  }

  const handleClickOutside = event => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setDropdownOpen(false)
    }
  }

  const handleNavLinkClick = () => {
    setMenuOpen(false)
    setDropdownOpen(false)
  }

  const handleDropdownClick = () => {
    // setActiveNavItem('/other');
    toggleDropdown()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const [langDropDownIsOpen, setLangDropDownIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const { i18n, t } = useTranslation()
  const changeLanguage = lang => {
    i18n.changeLanguage(lang)
    setLangDropDownIsOpen(false)
    window.location.reload()
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setLangDropDownIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <header ref={headerRef} className='header-container container'>
      <nav className='nav'>
        <Link to='/'>
          <img src={logo} alt='logo' className='header-logo-img' />
        </Link>

        <div className='toggle-container'>
          {!menuOpen && <RedBtn btnLabel='Войти' btnLink='/login' />}
          <button className='menu-toggle' onClick={toggleMenu}>
            <img src={menuOpen ? closeIcon : burgerIcon} alt='open and close icons' />
          </button>
        </div>

        <ul className={`nav-list ${menuOpen ? 'show' : ''}`}>
          <li className={`nav-item ${activeNavItem === '/' ? 'active-nav-item' : ''}`} onClick={() => setActiveNavItem('/')}>
            <Link className='nav-link' to='/' onClick={handleNavLinkClick}>
              {t('header.home')}
            </Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/about-bank' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/about-bank')}>
            <Link className='nav-link' to='/about-bank' onClick={handleNavLinkClick}>
              {t('header.about_the_bank')}
            </Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/tariffs-and-services' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/tariffs-and-services')}>
            <Link
              className='nav-link'
              to='/tariffs-and-services'
              onClick={handleNavLinkClick}
              dangerouslySetInnerHTML={{ __html: t('header.rates_and_services') }}></Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/corporate-clients' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/corporate-clients')}>
            <Link
              className='nav-link'
              to='/corporate-clients'
              onClick={handleNavLinkClick}
              dangerouslySetInnerHTML={{ __html: t('header.corporate_clients') }}></Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/physical-clients' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/physical-clients')}>
            <Link
              className='nav-link'
              to='/physical-clients'
              onClick={handleNavLinkClick}
              dangerouslySetInnerHTML={{ __html: t('header.individual_clients') }}></Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/payment-cards' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/payment-cards')}>
            <Link
              className='nav-link'
              to='/payment-cards'
              onClick={handleNavLinkClick}
              dangerouslySetInnerHTML={{ __html: t('header.payment_cards') }}></Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/crypto-operations' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/crypto-operations')}>
            <Link
              className='nav-link min-content'
              to='/crypto-operations'
              onClick={handleNavLinkClick}
              dangerouslySetInnerHTML={{ __html: t('header.cryptocurrency_operations') }}></Link>
          </li>

          <li
            className={`nav-item dropdown ${activeNavItem === '/other-services' ? 'active-nav-item' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleDropdownClick}>
            <div className='flex-row'>
              <span className='nav-link'>{t('header.other_services')}</span>

              <div className='arrow-container' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {dropdownOpen ? (
                  <img
                    src={activeNavItem === '/other-services' ? arrowUpRed : isUpArrowRed ? arrowUpRed : arrowUpBlue}
                    alt='arrow-up'
                    className='header-arrow'
                  />
                ) : (
                  <img
                    src={activeNavItem === '/other-services' ? arrowDownRed : arrowDownBlue}
                    alt='arrowdown'
                    className='header-arrow'
                  />
                )}
              </div>
            </div>

            {dropdownOpen && (
              <ul className='dropdown-content' onMouseEnter={() => setIsUpArrowRed(false)}>
                <li>
                  <NavLink
                    to='/other-services#deposit-account'
                    className='dropdown-nav-link'
                    onClick={() => {
                      handleScroll('deposit')
                      handleNavLinkClick()
                    }}>
                    {t('header.dd.deposit_account')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/other-services#cashing-checks'
                    className='dropdown-nav-link'
                    onClick={() => {
                      handleScroll('cashing-checks')
                      handleNavLinkClick()
                    }}>
                    {t('header.dd.cashing_of_bank_checks')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/other-services#international-acquiring'
                    className='dropdown-nav-link'
                    onClick={() => {
                      handleScroll('international-acquiring')
                      handleNavLinkClick()
                    }}>
                    {t('header.dd.international_acquiring')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/other-services#operations-metals'
                    className='dropdown-nav-link'
                    onClick={() => {
                      handleScroll('operations-metals')
                      handleNavLinkClick()
                    }}>
                    {t('header.dd.operations_with_precious_metals')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/other-services#sberkassa-fx'
                    className='dropdown-nav-link'
                    onClick={() => {
                      handleScroll('sberkassa-fx')
                      handleNavLinkClick()
                    }}>
                    {t('header.dd.international_sberKassa_FX')}
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <div className='language-container' ref={dropdownRef}>
            <div className='language-lable' onClick={() => setLangDropDownIsOpen(prev => !prev)}>
              <span>{i18n.language.substring(0, 2)}</span>
              <img src={langArrow} alt='arrow' />
            </div>
            {langDropDownIsOpen && (
              <div className='language-dropdown'>
                <p onClick={() => changeLanguage('en')}>EN</p>
                <p onClick={() => changeLanguage('ru')}>RU</p>
              </div>
            )}
          </div>
          <RedBtn btnLabel={t('buttons.login')} btnLink='/login' />
        </ul>
      </nav>
    </header>
  )
}

export default Header
